@import url(https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
body {
  background: #171717;
  overflow-x:hidden;
}

body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #171717;
  background: #171717;
  border-radius: 5px;
}
body::-webkit-scrollbar-thumb {
  background: #d288a2;
  border-radius: 5px;
}

body::-webkit-scrollbar-corner {
  background: #171717;
}


.navbar-dark .navbar-nav .nav-link {
  color: #d288a2;
  font-family: "Teko";
  font-size: 35px;
}

.bg-dark {
  background: #171717;
}

.home-pg {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: #fdfbf9;
}

.resume {
  width: 1000px;
  height: 100vh;
  float: left;
  background: #fdfbf9;
}

.background-pic {
  width: 1000px;
  height: 100vh;
  float: right;
}

.bg-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
}

.resume h1 {
  color: #d288a2;
  font-family: "Teko";
  font-size: 35px;
  margin-top: 20px;
}

Button {
  width: 250px;
  font-family: "Teko";
  margin: 1rem auto;
  float: right;
  margin-top: 100px;
}

.btn-dark {
  font-size: 30px;
  align-self: start;
  color: #d288a2;
  margin-right: 350px;
}
.access {
  position: absolute;
  top: 50%;
  left: 10%;
}
.about-pg {
  background: #171717;
  width: 100vw;
  height: 100vh;
  border-top: 10px solid #d288a2;
}

.scrollUp {
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 10px;
  float: right;
  position: sticky;
  right: 0;
  bottom: 0;
  padding: 10px;
}

.scrollUp i {
  color: #d288a2;
  font-size: 50px;
  font-weight: bold;
}

.within-bout {
  display: flex;
}

.about-pg .resume-btn {
  display: block;
  float: right;
  width: 950px;
  height: 20vh;
  margin-top: -70px;
}

.within-about{
margin: 40px;
}

.about-pg p {
  padding: 5px;
  color: #fdfbf9;
  font-size: 30px;
  font-family: "Teko";
  float: left;
  width: 800px;
  height: 30vh;
  margin: 20px;
}


.projects-pg {
  background: #171717;
  width: 100vw;
  height: 200vh;
  display: flex;
  flex-direction: column;
}

.projects-pg h2{
text-align: center;
color: #fdfbf9;
font-family: "Teko";
font-size: 24px;

}

.contacts-pg {
  background: #171717;
  width: 100vw;
  height: 100vh;
  margin-top: -150px;
}

.btn-primary {
    color: #fdfbf9;
    background: #171717;
    border: 2px solid #d288a2;
    margin: 10px;
    font-size: 24px;
    font-family: "Teko";
    position: absolute;
    bottom: 0;
    right: 1%;
    width: 15rem;
}

.btn-primary:hover {
  background: #fdfbf9;
  border: 2px solid #d288a2;
}
h1 {
  color: #fdfbf9;
  font-size: 50px;
  font-family: "Teko";
  text-align: center;
  letter-spacing: 0.1rem;
}

.project-cards {
  display: flex;
  margin-top: 70px;
  margin: 1rem auto;
  height: 600px;
  width: 80%;
  justify-content: center;
}
.projects-pg a {
  text-decoration: none;
  color: #d288a2;
  font-family: "Teko";
}

.projects-pg .card {
  background: transparent;
  color: #fdfbf9;
  display: flex;
  margin: 10px;
  height: 550px;
  box-shadow: 5px 5px 5px black;
  font-family: "Teko";
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
  height: 240px;
}

.contact-form {
  width: 1200px;
  height: 95vh;
  margin: 1rem auto;
  box-shadow: 5px 5px 5px 5px black;
}
.contact-me {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.contact-me input {
  width: 500px;
  height: 40px;
  margin: 10px;
  margin: 1rem auto;
  margin-top: 40px;
  border: none;
  outline: none;
  border-radius: 10px;
  border-bottom: 4px solid #d288a2;
  border-right: 2px solid #d288a2;
  background: transparent;
  color: #fdfbf9;
  font-family: "Teko";
  font-size: 24px;
  padding: 10px;
}

.contact-me textarea {
  background: transparent;
  height: 300px;
  width: 600px;
  margin: 1rem auto;
  padding: 5px;
  outline: none;
  border: none;
  border: 2px solid #d288a2;
  font-size: 24px;
  color: #fdfbf9;
  font-family: "Teko";
  border-radius: 10px;
}

.contact-me button {
  background: transparent;
  font-family: "Teko";
  font-size: 30px;
  border: none;
  border-radius: 5px;
  border: 2px solid #d288a2;
  padding: 5px;
  color: #d288a2;
  width: 250px;
  height: 65px;
  letter-spacing: 0.1rem;
  margin-top: 60px;
}

.contact-me button:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background: #fdfbf9;
}

.glitch{
  position: absolute;
  top: 90%;
  left: 23%; 
  text-align: center;
  color: #d288a2;
  font-size: 2.5em;
  font-weight: bold;
  font-family: "Teko"; 
  text-shadow:0.05em 0 0 #171717, -0.025em -0.05em 0 black, 0.025em 0.05em 0 grey;
  -webkit-animation: glitch infinite 600ms;
          animation: glitch infinite 600ms;
}

.glitch span{
  position: absolute;
  top: 0;
  left: 0;
}

.glitch span:first-child{
  -webkit-animation: glitch infinite 700ms;
          animation: glitch infinite 700ms;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
          clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  -webkit-transform: translate(-0.025em, -0.0125em);
          transform: translate(-0.025em, -0.0125em);
  opacity: .7;
}

.glitch span:last-child{
  -webkit-animation: glitch infinite 400ms;
          animation: glitch infinite 400ms;
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
            clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
  -webkit-transform: translate(-0.025em, -0.0125em);
          transform: translate(-0.025em, -0.0125em);
  opacity: .7;
}
 

@-webkit-keyframes glitch{
  0% {
     text-shadow:
       0.05em 0 0 #171717, 
       -0.05em -0.025em 0 black, 
       -0.025em 0.05em 0 grey;
  }

  
    15% {
   text-shadow: 
     -0.05em -0.025em 0 #171717,
     0.025em 0.025em 0 black, 
     -0.05em -0.05em 0 grey;
  }
  
      50% {
   text-shadow: 
     0.025em 0.05em 0 #171717,
     0.05em 0 0 black, 
    0 -0.05em 0 grey;
  }
  

  100% {
   text-shadow: 
     -0.025em 0 0 #171717, 
     -0.025em -0.025em 0 black, 
    -0.025em -0.05em 0 grey;
  }
  
  
}
 

@keyframes glitch{
  0% {
     text-shadow:
       0.05em 0 0 #171717, 
       -0.05em -0.025em 0 black, 
       -0.025em 0.05em 0 grey;
  }

  
    15% {
   text-shadow: 
     -0.05em -0.025em 0 #171717,
     0.025em 0.025em 0 black, 
     -0.05em -0.05em 0 grey;
  }
  
      50% {
   text-shadow: 
     0.025em 0.05em 0 #171717,
     0.05em 0 0 black, 
    0 -0.05em 0 grey;
  }
  

  100% {
   text-shadow: 
     -0.025em 0 0 #171717, 
     -0.025em -0.025em 0 black, 
    -0.025em -0.05em 0 grey;
  }
  
  
}


.image{
  display: flex;
  width: 250px;
  height: 100px;
  color: #fdfbf9;
  font-size: 60px;
  padding: 50px;
  margin: 20px;
  cursor: pointer;
  margin: 1rem auto;
  margin-top: -40px;
}

.image a{
z-index: 1;
font-size: 18px;
margin-left: 15px; 
text-direction: none;
color:#fdfbf9;  
 font-family: 'Teko'; 
 text-align: center; 
 margin-top: .5rem; 
  color: #d288a2;
}

.linked{
  display: flex;
  flex-direction: column;
  margin-left: -60px;
}

.git{
  display: flex;
  flex-direction: column;
  margin-left: 100px;
}


.fa-linkedin {
    padding: 10px 14px;
    transition: .5s;
    background-color: transparent;
  cursor: pointer;
  border: none;
  font-size: 50px;
  box-shadow: 1px 3px 3px 2px black;
  color: #d288a2;
}
.fa-linkedin:hover {
    box-shadow: 1px 3px 3px 2px #d288a2;
}


.fa-github {
    padding: 10px 14px;
    transition: .5s;
    background-color: transparent;
  cursor: pointer;
  border: none;
  font-size: 50px;
  box-shadow: 1px 3px 3px 2px black;
  color: #d288a2;
}
.fa-github:hover {
  box-shadow: 1px 3px 3px 2px #d288a2;
}

.mobile-apps{
  display: flex;
  margin-top: 70px;
  margin: 1rem auto;
  height: 600px;
  width: 80%;
  justify-content: center;
}

.icons i{
margin: 20px;
}

.icons {
    display: flex;
    margin: 10px;
    padding: 10px;
    justify-content: center;
    position: absolute;
    bottom: -2%;
    left: 0;
    right: 0;
}

.android{
display: flex;
flex-direction: column;
  text-align: center;
}

.fa-android {
    padding: 10px 14px;
    transition: .5s;
    background-color: transparent;
  cursor: pointer;
  border: none;
  box-shadow: 1px 3px 3px 2px black;
  font-size: 40px;

}


.fa-android:hover {
    box-shadow: 1px 3px 3px 2px #d288a2;
  color: #d288a2;
}


.ios{
display: flex;
flex-direction: column;
  text-align: center;
}


.fa-apple {
    padding: 10px 14px;
    transition: .5s;
    background-color: transparent;
  cursor: pointer;
  border: none;
  box-shadow: 1px 3px 3px 2px black;
  font-size: 40px;
}

.fa-apple:hover {
    box-shadow: 1px 3px 3px 2px #d288a2;
  color: #d288a2;
}

.viewResume{
background: #171717;
color: #fdfbf9;
}

.popup{
  background: #171717;
  width: 1800px;
  height: 780px;
  margin: 1rem auto;
  box-shadow: 5px 5px 5px 5px #d288a2;
  color: #fdfbf9;
  border-radius: 4px;
  padding: 20px;
  overflow: scroll;
}

.popup::-webkit-scrollbar{
    width: 12px;
  }

.popup::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black; 
  background-color: #171717;
  border-radius: 5px;
}

.popup::-webkit-scrollbar-thumb {
  background: black; 
  border-radius: 5px;
}

.popup::-webkit-scrollbar-corner {
background: #171717;
}

.contact{
  width: 1000px;
  height: 100%;
  margin: 1rem auto;
  display: none;
}

.contact ol li {
  font-size: 30px;
  text-decoration: none;
  list-style-type: none;
  margin: 20px;
  font-family: "Teko"; 
  margin-top: 50px;
  letter-spacing: .05rem;
}
.contact h1{
  text-align: center;
  font-size: 50px;
}
.education{
  width: 1000px;
  height: 100%;
  margin: 1rem auto;
  font-family: "Teko";
  letter-spacing: .05rem;
  
}

.education h1{
  text-align: center;
  font-size: 50px;
}

.education ul li {
  font-size: 20px;
  margin: 15px;
  font-family: "Teko"; 
  margin-top: 20px;
  letter-spacing: .05rem;
  
}


.skills{
  width: 1000px;
  height: 100%;
  margin: 1rem auto;
  font-family: "Teko";
  letter-spacing: .05rem;
  display: none;
  
}

.skills h1{
  text-align: center;
  font-size: 50px;
}

.skills ul li {
  font-size: 20px;
  margin: 15px;
  font-family: "Teko"; 
  margin-top: 20px;
  letter-spacing: .05rem;
  
}

.workhistory{
  width: 1000px;
  height: 100%;
  font-family: "Teko";
  letter-spacing: .05rem;
  padding: 20px;
  margin: 1rem auto;
  display:none;
}

.workhistory h1{
  text-align: center;
  font-size: 50px;

}

.workhistory ul li {
  font-size: 20px;
  margin: 15px;
  font-family: "Teko"; 
  margin-top: 20px;
  letter-spacing: .05rem;
  
}


#company{
  font-size: 26px;
text-align: left;
}


.pagination{
  width: 700px;
  height: 60px;
  background: #171717;
  color: #FDFBF9;
  border-radius: 250px;
  position: absolute;
  top: 700px;
  left: 28%;
  padding: 5px;
  margin: 10px;
  opacity: 1;
  display: flex;
  align-items: center;
}

.pagination button{
  width: 120px;
  height: 35px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background: #171717;
  font-size: 22px;
  font-family: "Teko";
  letter-spacing: .1rem;
  box-shadow: 5px 5px 5px 5px black;
  margin-bottom: 105px;
}

.next{
  color: #FDFBF9;
}

.previous{
  color: #FDFBF9;
}

.pagination ul li{
  list-style-type: none;
  display: inline-block;
  margin-right: 40px;
  font-weight: bold;
  font-size: 25px;
  cursor: pointer;
  font-family: "Teko";
  position: relative;
  text-align: center;
}

.current-pg{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  box-shadow: 2px 2px 2px 2px #d288a2;
}


/* .design-mockups{
  display: flex;
  margin-top: 70px;
  margin: 1rem auto;
  height: 600px;
  width: 80%;
  justify-content: center;
} */

.ReactModalPortal .ReactModal__Overlay{
background-color: transparent;
}

.ReactModalPortal .ReactModal__Content{
  background: transparent;
  border: none;
  inset: 0;
}


/* CUBE */

* {
  margin: 0;
  padding: 0;
  
}

body{
  background: #171717;
}

#cube .front {
  -webkit-transform: translateZ(100px);
          transform: translateZ(100px);
}

#cube .back{
  -webkit-transform: rotateX(-180deg)  translateZ(100px);
          transform: rotateX(-180deg)  translateZ(100px);
}

#cube .right{
  -webkit-transform: rotateY(90deg)  translateZ(100px);
          transform: rotateY(90deg)  translateZ(100px);
}

#cube .left{
  -webkit-transform: rotateY(-90deg)  translateZ(100px);
          transform: rotateY(-90deg)  translateZ(100px);
}
#cube .top{
  -webkit-transform: rotateX(90deg)  translateZ(100px);
          transform: rotateX(90deg)  translateZ(100px);
}

#cube .bottom{
  -webkit-transform: rotateX(-90deg)  translateZ(100px);
          transform: rotateX(-90deg)  translateZ(100px);
}


.container{
  display: flex;
  width: 200px;
  height: 200px;
  margin: 0 auto 40px;
  margin-top: 200px;
  position: relative;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  -webkit-perspective-origin: 50% 100%;
          perspective-origin: 50% 100%;
}


#cube{
  width: 100%;
  height: 100%;
  top: 100px;
  position:absolute;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: -webkit-transform 6s;
  transition: transform 6s;
  transition: transform 6s, -webkit-transform 6s;
  -webkit-animation: rotate 4s infinite;
          animation: rotate 4s infinite;
  
}

#cube div{
  background: #d288a2;
  opacity: .9;
  display: flex;
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid black;
  margin: 0 auto;
  font-family: Arial NOVA;
  font-size: 500%;
  text-align: center;
  padding: 50px 0;

}

#cube img{
  width: 100%;
  height: 100%;
  
}

@-webkit-keyframes rotate{
  0%{
   -webkit-transform: rotateY(45deg);
           transform: rotateY(45deg);
  }
  
  20%{
    -webkit-transform: rotateY(-45deg);
            transform: rotateY(-45deg);
  }
  
  40%{
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
  }
  
  60%{
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    
  }
  
   80%{
    -webkit-transform: rotateY(189deg);
            transform: rotateY(189deg);
  }
    100%{
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
  }
  
}

@keyframes rotate{
  0%{
   -webkit-transform: rotateY(45deg);
           transform: rotateY(45deg);
  }
  
  20%{
    -webkit-transform: rotateY(-45deg);
            transform: rotateY(-45deg);
  }
  
  40%{
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
  }
  
  60%{
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    
  }
  
   80%{
    -webkit-transform: rotateY(189deg);
            transform: rotateY(189deg);
  }
    100%{
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
  }
  
}

